import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from 'react-bootstrap';

//import en from "../../../public/en.svg";
import Flag from 'react-world-flags';

import EnIcon from "./en.svg";
//import ru from './ru1.svg';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (e:any) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang);
  };
  /*
<option value="en" key="en"><div><Flag code="gbr" height={15}/></div></option>
      <option value="lt" key="lt">sdf<Flag code="ltu" height={15}/></option>
      <option value="uk" key="uk"><Flag code="ukr" height={15}/></option>
      <option value="ru" key="ru"><Flag code="rus" height={15}/></option>
      <option style={{backgroundImage:'./uk.png', width: 20, height: 20}}>AWS


      <option value="lt" key="lt">sdf<Flag code="ltu" height={15}/></option>
      <option value="uk" key="uk"><Flag code="ukr" height={15}/></option>
      <option value="ru" key="ru"><Flag code="rus" height={15}/></option>
*/

  return (
    <select value={i18n.language} onChange={handleLanguageChange}>
        <option value="en" key="en">EN</option>
        {/*<option value="lt" key="lt">LT</option>
        <option value="uk" key="uk">UK</option>*/}
        <option value="ru" key="ru">RU</option>
    </select>
  );
};

export default LanguageSwitcher;