import { FC, useState } from 'react';
import Incident from '../../models/incident.model';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import ConfigService from '../../config/config.service';
import Markdown from 'react-markdown'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Clipboard, ClipboardX } from 'react-bootstrap-icons';
import * as icons from 'react-bootstrap-icons';



const config = ConfigService();

interface IncidentsProps {
    incident: Incident
}

const IncidentModalDetails: FC<IncidentsProps> = ({ incident }) => {
    const { t, i18n } = useTranslation();
    let [copyStatus, setCopyStatus] = useState(false);

    const isNotImage = (fileName: string) => {
        if (fileName.indexOf(".doc") !== -1 || fileName.indexOf(".xls") !== -1
            || fileName.indexOf(".docx") !== -1 || fileName.indexOf(".xlsx") !== -1
            || fileName.indexOf(".ppt") !== -1 || fileName.indexOf(".pptx") !== -1
            || fileName.indexOf(".pdf") !== -1 || fileName.indexOf(".csv") !== -1
            || fileName.indexOf(".odp") !== -1 || fileName.indexOf(".ods") !== -1
            || fileName.indexOf(".odt") !== -1) {
            return true;
        }
        return false;
    }

    return (
        <Container fluid color={'#000'} style={{ backgroundColor: '#000', color: '#fff', textDecorationColor: '#fff' }}>

            <Row>
                <Col md={11} style={{ color: '#ECECEC' }}><b>{incident.title}</b></Col>
            </Row>
            <Row>
                <Col md={11}><br /></Col>
            </Row>
            <Row>
                <Col md={11} style={{ color: '#CBCBCE' }}>
                    <Markdown>{incident.getDescription(i18n.language)}</Markdown>
                </Col>
            </Row>
            <Row>
                <Col md={11}><br /></Col>
            </Row>
            <Row>
                <Col md={11} ><b style={{ color: '#ECECEC' }}>{t('incidents.modal.drones')}</b>
                    <text style={{ color: '#CBCBCE' }}> {incident.drones}</text></Col>
            </Row>
            <Row>
                <Col md={11}><br /></Col>
            </Row>
            <Row>
                <Col md={11}><b style={{ color: '#ECECEC' }}>{t('incidents.modal.volume')}</b>
                    <text style={{ color: '#CBCBCE' }}> {incident.volume}</text></Col>
            </Row>
            <Row>
                <Col md={11}><br /></Col>
            </Row>
            <Row>
                <Col md={11}>
                    <div style={{ display: 'inline-block', width: '100%' }}>
                        <b style={{ color: '#ECECEC' }}>{t('incidents.modal.coords')}</b>
                        <text style={{ color: '#CBCBCE' }}> {incident.lat},{incident.lng}</text>&nbsp;&nbsp;&nbsp;
                        <CopyToClipboard text={`${incident.lat},${incident.lng}`}
                            onCopy={() => { setCopyStatus(true); console.log('copied'); setTimeout(() => setCopyStatus(false), 2000); }}>
                            <div style={{ display: 'inline-block' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                </svg>
                                {
                                    copyStatus && <span style={{

                                        color: '#CBCBCE',
                                        borderRadius: 5, padding: '5px 10px',
                                        position: 'relative',
                                        top: -25, left: -30,
                                        borderColor: '#CBCBCE'
                                    }
                                    }>Copied</span>
                                }
                            </div>
                        </CopyToClipboard>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={11}><br /></Col>
            </Row>
            <Row>
                <Col md={11}>
                    {
                        incident.fileName && incident.fileName.map((f: any, index: any) => {
                            if (isNotImage(f)) {
                                return <><a key={`a${f}${index}`} href={`${config.IMAGES_UPLOAD_ENDPOINT}/${f}`}>{incident.originalFileName[index]}</a><br /></>
                            }
                        })
                    }
                </Col>
            </Row>

        </Container>
    );
};

export default IncidentModalDetails;