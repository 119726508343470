import React, { useState, FC, useEffect } from 'react';
import { Link } from "react-router-dom";
import User from '../../models/user.model';
import axios from 'axios';
import ConfigService from '../../config/config.service';

const config = ConfigService();

const UsersList: FC = () => {
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        const getAllUsers = async () => {
            const response = await axios.get(config.USERS_ENDPOINT);
            console.log(response);
            return response.data;
        }

        getAllUsers().then(users => {
            console.log(users)
            setUsers(users);
        });
    }, []);

    return (
        <div>
            <div>
            <Link to="/CreateUser">Create user</Link>
            </div>
            <div>
                {users.map((user: User) => <div>{user._id} - {user.a}</div>)}
            </div>
        </div>

    )
}

export default UsersList;