import { useMap } from 'react-leaflet';
import { FC, useEffect } from 'react';


interface MapZoomProps {
    center: any;
    zoom: any;
}

const MapZoom: FC<MapZoomProps> = ({ center, zoom }) => {
    const map = useMap();

    useEffect(()=> {
        map.setView(center, zoom);
    }, [center]);
    return (
        <></>
    );

}

export default MapZoom;
