import { FC } from 'react';
import ConfigService from '../../config/config.service';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import "react-datepicker/dist/react-datepicker.css";
import { Calendar3, Postcard, Compass, Pencil } from 'react-bootstrap-icons';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import './header.css';
import LanguageSwitcher from '../language/language.component';

const config = ConfigService();

interface HeaderComponentProps {
  setMenu: (e: any) => void;
  changeMapView: (lat: any, lng: any, zoom: number) => void;
}

const HeaderComponent: FC<HeaderComponentProps> = ({ setMenu, changeMapView }) => {
  const { t } = useTranslation();

  let places: any[] = [];
  config.PLACES.split(';').forEach((entry) => {
    var e = entry.split(':');
    places.push({
      place: e[0],
      lat: Number(e[1]),
      lng: Number(e[2]),
      zoom: Number(e[3])
    });
  });
  console.log(process.env);

  return (
    <Container fluid>
      <Row>
        <Col md={3} style={{alignSelf: 'center'}}>
          <img src={`${process.env.PUBLIC_URL}/logo.svg`} key="logo" width={200}/>
        </Col>
        <Col md={6}>
          <Nav className="locnav" variant="pills" defaultActiveKey="#">
            {
              places.map(entry => {
                return <Nav.Item>
                  <Nav.Link  eventKey={`link-${entry.place}`} style={{color: '#FEEE03'}}
                    onClick={() => changeMapView(entry.lat, entry.lng, entry.zoom)}>
                      {entry.place}</Nav.Link>
                </Nav.Item>
              })
            }
            {/*
            <Nav.Item>
              <Nav.Link href="#" onClick={() => changeMapView(55.17, 23.57, 7)}>Lietuva</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-1" onClick={() => changeMapView(54.697826, 25.283926, 12)}>Vilnius</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2" onClick={() => changeMapView(54.903435, 23.903594, 12)}>Kaunas</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-3" onClick={() => changeMapView(55.713249, 21.145212, 12)}>Klaipėda</Nav.Link>
            </Nav.Item>
          */}
          </Nav>
        </Col>
        <Col md={3}>
          <Table borderless>
            <tbody className="use">
              <tr>
                <td height={30} align={'center'} ><Postcard height={30} width={30} color={'#FEEE03'} onClick={() => setMenu('news')} /></td>
                <td height={30} align={'center'}><Calendar3 height={30} width={30} color={'#FEEE03'} onClick={() => setMenu('filter')} /></td>
                <td height={30} align={'center'}><Compass height={30} width={30} color={'#FEEE03'} onClick={() => setMenu('key')} /></td>
                {/*<td height={30} align={'center'}><Pencil height={30} width={30} color={'#FEEE03'} onClick={() => setMenu('add')} /></td>*/}
                <td><LanguageSwitcher /></td>
              </tr>
              <tr>
                <td className='menu' align={'center'}>{t('header.news')}</td>
                <td className='menu' align={'center'}>{t('header.filter')}</td>
                <td className='menu' align={'center'}>{t('header.key')}</td>
                {/*<td className='menu' align={'center'}>{t('header.add')}</td>*/}
                <td></td>
              </tr>
            </tbody>

          </Table>
        </Col>
      </Row>
    </Container>

  )
}

export default HeaderComponent;