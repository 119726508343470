declare global {
    interface Window {
        USERS_ENDPOINT: string,
        INCIDENTS_ENDPOINT: string,
        IMAGES_ENDPOINT: string,
        IMAGES_UPLOAD_ENDPOINT: string
        KEYS_ENDPOINT: string,
        CENTER_LAT: number,
        CENTER_LNG: number,
        PLACES: string,
        CENTER_ZOOM: number,
        ADD_MARKER_ENABLED: string,
        ADD_POSITION_ENABLED: string
    }
}

const config = {
    USERS_ENDPOINT: window.USERS_ENDPOINT,
    INCIDENTS_ENDPOINT: window.INCIDENTS_ENDPOINT,
    IMAGES_ENDPOINT: window.IMAGES_ENDPOINT,
    IMAGES_UPLOAD_ENDPOINT: window.IMAGES_UPLOAD_ENDPOINT,
    KEYS_ENDPOINT: window.KEYS_ENDPOINT,
    CENTER_LAT: window.CENTER_LAT || 55.17,
    CENTER_LNG:  window.CENTER_LNG || 23.57,
    PLACES: window.PLACES,
    CENTER_ZOOM: window.CENTER_ZOOM || 7,
    ADD_MARKER_ENABLED: window.ADD_MARKER_ENABLED || "false",
    ADD_POSITION_ENABLED:  window.ADD_POSITION_ENABLED || "false"

}


const ConfigService = () => config;

export default ConfigService;