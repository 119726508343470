import * as React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import UsersList from './components/users/users.component';
import CreateUser from './components/users/create.user.component';
import MapView from './components/maps/map.component';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MapView />} />
      <Route path="/CreateUser" element={<CreateUser />} />
      <Route path="/List" element={<UsersList />} />
    </Routes>

  );
}

export default App;

