import { LatLng } from "leaflet";
import WorkHours from "./workhours.model";

export default class Incident {
    title: string;
    descriptionEn: string;
    descriptionRu: string;
    lat: number;
    lng: number;
    createTimestamp: Date;
    fileName: string[];
    type: string;
    workHours: WorkHours;
    originalFileName: string[];
    _id: string;
    drones: string;
    volume: string;

    constructor(title: string, descriptionEn: string,
        descriptionRu:string, lat: number,
        lng: number, createTimestamp: Date, fileName: string[],
        type: string,
        originalFileName: string[], drones: string, volume: string) {
        this.title = title;
        this.descriptionEn = descriptionEn;
        this.descriptionRu = descriptionRu;
        this.lat = lat;
        this.lng = lng;
        this.createTimestamp = createTimestamp;
        this.fileName = fileName;
        this.type = type;
        this.originalFileName = originalFileName;
        this.drones = drones;
        this.volume = volume;
    }

    public getPosition(): LatLng {
        return new LatLng(this.lat, this.lng);
    }
    public setId(id: string) {
        this._id = id;
    }

    /*public getId(id: string) {
        return this._id;
    }*/

    public getDescription(language:string): string {
        if ('ru' === language) {
            return this.descriptionRu || '';
        } else {
            return this.descriptionEn || '';
        };
    }

    public getCreateTimestampIsoDate(): string{
        return `${this.createTimestamp.toLocaleString('default', { year: 'numeric'})}-${this.createTimestamp.toLocaleString('default', { month: '2-digit'})}-${this.createTimestamp.toLocaleString('default', { day: '2-digit'})} ${this.createTimestamp.toLocaleString('default', { hour: '2-digit'})}:${this.createTimestamp.toLocaleString('default', { minute: '2-digit'})}`;
    }
}
