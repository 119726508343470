import { useState, FC } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UsersList from './users.component';
import ConfigService from '../../config/config.service';

const config = ConfigService();

const CreateUser: FC = () => {
    const navigate = useNavigate();

    const [a, setA] = useState("");

    const handleChange = (event: any) => {
        setA(event.target.value);
    }

    const createUser = async (a: string) => {
        const response = await axios.post(config.USERS_ENDPOINT, { a: a });
        console.log(response);
        return response.data;
    }

    const handleSubmit = (event: any) => {
        createUser(a).then(users => {
            console.log('USER: ', users);
            event.preventDefault();
            navigate('/List');
        });

    }

    return (
        <div>
            <div>
                <form onSubmit={handleSubmit}>
                    <label>
                        Name:
                        <input type="text" value={a} onChange={handleChange} />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
            </div>
            <div>
                <UsersList />
            </div>
        </div >

    )
}

export default CreateUser;