import { FC, useEffect, useState, useMemo, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MapContainer, TileLayer, Marker, Popup, ScaleControl } from 'react-leaflet';
import CreateIncident from '../incidents/create.incident.component';
import L, { LatLng, latLngBounds } from "leaflet";
import Incident from '../../models/incident.model';
import ConfigService from '../../config/config.service';
import axios from 'axios';
import IncidentsList from '../incidents/incidents.component';
import 'leaflet.markercluster';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import HeaderComponent from '../header/header.component';
import FilterComponent from '../filter/filter.component';
import createIcon from './icons.helper';
import Key from '../../models/key.model';
import KeysList from '../key/key.component';
import { useTranslation } from 'react-i18next';
import { TagCloud } from 'react-tagcloud';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
} from "recharts";
import { format } from "date-fns";
import MapZoom from './map.zoom';


const _ = require('lodash');

const config = ConfigService();

const MapView: FC = () => {
    const [position, setPosition] = useState(new LatLng(config.CENTER_LAT, config.CENTER_LNG));
    const [center, setCenter] = useState(new LatLng(config.CENTER_LAT, config.CENTER_LNG));
    const [zoom, setZoom] = useState(config.CENTER_ZOOM);
    const [filteredIncidents, setFilteredIncidents] = useState<Incident[]>([]);

    const [incidents, setIncidents] = useState<Incident[]>([]);

    const [refreshIncidents, setRefreshIncidents] = useState<boolean>(false)
    const [marker, setMarker] = useState(null)
    const [menu, setMenu] = useState('news');
    const [keys, setKeys] = useState([]);
    const { t, i18n } = useTranslation();
    const [wordCount, setWordCount] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [incidentId, setIncidentId] = useState('');


    const d = new Date();
    d.setMonth(d.getMonth() - 10);
    const [fromDate, setFromDate] = useState(d);
    const [toDate, setToDate] = useState(new Date());
    const [keysFilter, setKeysFilter] = useState([]);

    const [markersLayer, setMarkersLayer] = useState(L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        maxClusterRadius: 0.1,
        removeOutsideVisibleBounds: true
    }));

    const changeMapView = (lat: any, lng: any, zoom: number) => {
        setCenter(new LatLng(lat, lng));
        setZoom(zoom);
    }

    const [markerBounds, setMarkerBounds] = useState(latLngBounds([]));

    const markerRef = useRef(null);

    const fromDateFormat = (a: Date) => {
        return `${a.toLocaleString('default', { year: 'numeric' })}-${a.toLocaleString('default', { month: '2-digit' })}-${a.toLocaleString('default', { day: '2-digit' })}T00:00:00.000Z`;
    }

    const toDateFormat = (a: Date) => {
        return `${a.toLocaleString('default', { year: 'numeric' })}-${a.toLocaleString('default', { month: '2-digit' })}-${a.toLocaleString('default', { day: '2-digit' })}T23:59:59.000Z`;
    }
    const getAllIncidents = async (from?: Date, to?: Date) => {
        //const response = await axios.get(`${config.INCIDENTS_ENDPOINT}?from=${fromDateFormat(from)}&to=${toDateFormat(to)}`);
        const response = await axios.get(`${config.INCIDENTS_ENDPOINT}`);
        return response.data;
    }

    const getKeys = async () => {
        const response = await axios.get(`${config.KEYS_ENDPOINT}`);
        return response.data;
    }

    const getMarkerContent = (fileName: string[]) => {
        let content = '';
        if (typeof fileName != 'undefined') {
            fileName?.map(a => {
                if (a.toLowerCase().indexOf('.png') !== -1
                    || a.toLowerCase().indexOf('.jpg') !== -1
                    || a.toLowerCase().indexOf('.jpeg') !== -1) {
                    if (content === '') {
                        content = '<br/>'
                    }
                    content = content + "" + `<img key="imgkey${a}" src="${config.IMAGES_UPLOAD_ENDPOINT}/${a}" crossOrigin="" height="30%" width="30%" />`;
                }
            });
        }
        return content;
    };

    useEffect(() => {
        getKeys().then(keys => {
            let kArray: Key[] = [];
            keys.forEach((e: Key) => {
                kArray.push(e);
            });
            setKeys(kArray);
        })

    }, []);

    const copyTextToClipboard = async (e:any) => {
        console.log('text copy', e);
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText('asdfasdf');
        } else {
            return document.execCommand('copy', true, 'werwerwer');
        }
    }

    const processData = (e: any, iArray: any[], str: any, areaArray: any[]) => {
        let index = keys.find(k => k.identifier === e.type);
        const incident = new Incident(e.title, e.descriptionEn,
            e.descriptionRu, e.lat, e.lng,
            new Date(e.createTimestamp), e.fileName, e.type,
            e.originalFileName, e.drones, e.volume);
        incident.setId(e._id);
        iArray.push(incident);
        const lMarker = L.marker([
            e.lat,
            e.lng
        ], { icon: createIcon(index?.color, index?.icon) });
        //lMarker[id] = e._id;
        markerBounds.extend([e.lat, e.lng]);
        let content = `<div key="popup_content" id="${e._id}"><b style="color: #333333;">${e.title}</b>`;
        if (e.drones?.length > 0) {
            content += `<br/><br/><b style="color: #333333;">${t('map.drones')}</b> ${e.drones}<br/>`
        }
        if (e.volume?.length > 0) {
            content += `<br/><br/><b style="color: #333333;">${t('map.volume')}</b> ${e.volume}<br/>`
        }
        content += `<br/><br/><b style="color: #333333;">${t('map.coords')}</b> ${e.lat},${e.lng}<br/>`;
       
        content += getMarkerContent(e.fileName);
        content += "</div>";
        const popup = L.popup()
            .setContent(content);
        lMarker.bindPopup(popup).on("popupopen", (event) => {
            const index = event.popup.getContent().toString().indexOf("id") + 4;
            let str = event.popup.getContent().toString().substring(index);
            const index2 = str.indexOf("\"");
            setIncidentId(str.substring(0, index2));
        }).on("popupclose", function (event) {
            setIncidentId('');
        });

        markersLayer.addLayer(lMarker);
        str = str.concat(' ').concat(incident.getDescription(i18n.language).toLowerCase()).concat(' ').concat(e.title.toLowerCase());
        areaArray.push(`${(new Date(e.createTimestamp)).getFullYear()}-${(new Date(e.createTimestamp)).getMonth() + 1}-${(new Date(e.createTimestamp)).getDate()}`);
    }

    useEffect(() => {
        let iArray: Incident[] = [];
        markersLayer.clearLayers();
        let str = '';
        let areaArray: string[] = [];
        filteredIncidents.forEach((e: Incident, index: number) => {
            processData(e, iArray, str, areaArray);
        });

    }, [filteredIncidents]);

    useEffect(() => {
        getAllIncidents(fromDate, toDate).then(incidents => {
            let iArray: Incident[] = [];
            markersLayer.clearLayers();
            let str = '';
            let areaArray: string[] = [];
            incidents.items.forEach((e: Incident, index: number) => {

                if ((keysFilter.length === 0 || (keysFilter.includes(e.type)))) {
                    processData(e, iArray, str, areaArray);
                }
            });
            setIncidents(iArray);

            setMarkersLayer(markersLayer);
            setMarkerBounds(markerBounds);
            setRefreshIncidents(false);

            let groupedData = _.groupBy(_.words(str));
            let wArray = _.values(groupedData).map((a: any) => { return { "value": a[0], "count": a.length } });
            setWordCount(wArray);

            let groupedArea = _.groupBy(areaArray);
            let aData = _.values(groupedArea).map((a: any) => { return { 'd': new Date(a[0]).getTime(), 'val': a.length } });
            setAreaData(aData);
        });
    }, [refreshIncidents, fromDate, toDate, keys]);

    const addMarker = (p: any) => {
        if (p) {
            setPosition(p);
        }
        setMarker({ pos: position });

    }

    const removeMarker = () => {
        setMarker(null);

    }
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                }
            },
        }),
        [],
    );

    const setLocation = (e: any) => {
        setMarker({
            pos: {
                lat: e.latitude,
                lng: e.longitude
            }
        });
    }

    const myCustomColour = '#583470'

    const markerHtmlStyles = `
  background-color: ${myCustomColour};
  width: 2rem;
  height: 2rem;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF`

    const icon = L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 24],
        //labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`
    });
    //                        <LocationMarker setPositionOut={setPosition} />

    const dateFormatter = (date: any) => {
        if (isFinite(date)) {
            return format(new Date(date), "dd/MMM");
        } else {
            return '';
        }
    };
    //                        <LocationMarker setPositionOut={setPosition} setLocation={setLocation} />

    return (
        <Container fluid style={{ color: '#fff' }}>
            <Row>
                <Col md={12}>
                    <HeaderComponent setMenu={setMenu} changeMapView={changeMapView} />
                </Col>
            </Row>
            <Row>
                <Col md={9}>
                    <MapContainer center={center} zoom={zoom}
                        className='markercluster-map'
                        style={{ zIndex: 0 }}
                        maxZoom={18}
                        layers={[markersLayer]}
                        maxBounds={markerBounds}


                    >
                        <ScaleControl position="bottomright" />
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        {
                            marker != null && <Marker key={'a-999'}
                                position={marker.pos}
                                draggable={true}
                                ref={markerRef}
                                eventHandlers={eventHandlers}
                                icon={icon}

                            >
                                <Popup>
                                    New Marker
                                </Popup>
                            </Marker>
                        }
                        <MapZoom center={center} zoom={zoom} />

                    </MapContainer>
                    <br />
                    {/*<AreaChart
                        width={900}
                        height={250}
                        data={areaData}
                        margin={{
                            top: 10,
                            right: 0,
                            bottom: 10,
                            left: 0
                        }}
                    >
                        <Tooltip cursor={false} labelFormatter={(val) => `${format(new Date(val), 'dd/MMM')}`}
                            formatter={(value, name) => [value, `${t('map.area.xaxis')}`]}
                        />

                        <CartesianGrid strokeDasharray="3 3" />

                        <XAxis
                            dataKey='d'
                            domain={['dataMin', 'dataMax']}
                            name='Naujienos'
                            scale='time'
                            type='number'
                            tickFormatter={dateFormatter}
                        />
                        <YAxis label={{ value: t('map.area.xaxis'), position: 'insideTopLeft' }} />
                        <Area
                            type="monotone"
                            dataKey="val"
                            stroke="#ff7300"
                            fill="#ff7300"
                            fillOpacity={0.9}
                        />
                    </AreaChart>*/}
                    <br />
                    {/*<TagCloud
                        minSize={10}
                        maxSize={35}
                        tags={wordCount}
                    />
                    */}
                </Col>
                <Col md={3}>
                    {
                        menu === 'news' && <IncidentsList incidents={incidents} incidentId={incidentId} setFilter={setFilteredIncidents} />
                    }
                    {
                        menu === 'filter' && <FilterComponent setFromDateFilter={setFromDate}
                            setToDateFilter={setToDate} to={toDate} from={fromDate}
                            setKeysFilter={setKeysFilter} setRefreshIncidents={setRefreshIncidents}
                            keysFilter={keysFilter}
                        />
                    }
                    {
                        menu === 'add' && <CreateIncident position={position}
                            setRefreshIncidents={setRefreshIncidents}
                            addMarker={addMarker}
                            removeMarker={removeMarker}
                            setLocation={setLocation}
                            setMenu={setMenu}
                            setPosition={setPosition} />
                    }
                    {
                        menu === 'key' && <KeysList />
                    }
                    {
                        menu === 'thank' && <div>{t('incidents.thank.text')}</div>
                    }
                </Col>

            </Row>
        </Container >
    )

}

export default MapView;