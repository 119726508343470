import { FC, useState, useEffect } from 'react';
import ConfigService from '../../config/config.service';
import Incident from '../../models/incident.model';
import Modal from 'react-modal';
import { XCircle } from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import IncidentModalDetails from './incident.modal.details.component';
import ImageModalDetails from './incident.modal.image.component';


const config = ConfigService();


interface IncidentsProps {
    incident: Incident,
    modalIsOpen: boolean,
    setIsOpen: (e: any) => void
}

const IncidentModal: FC<IncidentsProps> = ({ incident, modalIsOpen, setIsOpen }) => {
    const [imageArray, setImageArray] = useState([]);
    const [popupMenu, setPopupMenu] = useState('details');
    const [incidentImage, setIncidentImage] = useState('');
    const [popupSize, setPopupSize] = useState({ image: 4, details: 8 });



    const { t } = useTranslation();

    useEffect(() => {
        let iArray: string[] = [];
        incident.fileName?.forEach((f: string) => {
            if (!isNotImage(f)) {
                iArray.push(f);
            }
        });
        setImageArray(iArray);
    }, [incident]);

    const customStyles = {
        content: {
            //top: '50%',
            //left: '50%',
            //right: 'auto',
            //bottom: 'auto',
            //marginRight: '-50%',
            //transform: 'translate(-50%, -50%)',
            zIndex: 99999,
            backgroundColor: '#000',
            background: '#000',
            color: '#000'
        },
        overlay: {
            backgroundColor: '#000',
            background: '#000',
            color: '#000'
        }
    };

    const closeModal = () => {
        console.log('closmoald');
        setIsOpen(false);
    }

    const isNotImage = (fileName: string) => {
        if (fileName?.indexOf(".doc") !== -1 || fileName?.indexOf(".xls") !== -1
            || fileName?.indexOf(".docx") !== -1 || fileName?.indexOf(".xlsx") !== -1
            || fileName?.indexOf(".ppt") !== -1 || fileName?.indexOf(".pptx") !== -1
            || fileName?.indexOf(".pdf") !== -1 || fileName?.indexOf(".csv") !== -1
            || fileName?.indexOf(".odp") !== -1 || fileName?.indexOf(".ods") !== -1
            || fileName?.indexOf(".odt") !== -1) {
            return true;
        }
        return false;
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            onRequestClose={closeModal}
        >
            <Container fluid color={'#000'} style={{ backgroundColor: '#000', color: '#fff', textDecorationColor: '#fff' }}>
                <Row>
                    <Col md={4} style={{ display: 'flex', justifyContent: 'center' }}>{imageArray.length > 0 && <text>{t('incidents.modal.photos')}</text>}</Col>
                    <Col md={8} style={{ display: 'flex', justifyContent: 'right' }}>
                        <XCircle height={30} width={30} onClick={closeModal} />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>{
                        imageArray.length > 0 && <Row>
                            <Col>
                                <Carousel dynamicHeight>

                                    {//setIncidentImage(f); setPopupMenu('image');
                                        imageArray.map((f, index) =>
                                            <div >
                                                <img key={`imgkey${f}${index}`} src={`${config.IMAGES_UPLOAD_ENDPOINT}/${f}`} crossOrigin={""} />
                                                <p className="legend">Legend 1</p>
                                            </div>
                                        )}

                                </Carousel>
                            </Col>
                        </Row>
                    }</Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <IncidentModalDetails incident={incident} />
                        
                    </Col>
                </Row>


            </Container>
        </Modal>
    );

}

export default IncidentModal;
